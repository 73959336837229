import React, { Suspense, useEffect, useRef, useMemo, useState } from "react"
import { Canvas, useLoader, useFrame, useThree } from "react-three-fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import * as THREE from "three"


const Floor = () => {
  const { nodes } = useLoader(GLTFLoader, "/assets/floor.glb");
  return <primitive object={nodes.Scene} dispose={null} />
};



const Laptop = () => {
  const { nodes } = useLoader(GLTFLoader, "/assets/laptop.glb");
  return <primitive object={nodes.Scene} dispose={null} />
};

const Char = (props) => {
  const gltf = useLoader(GLTFLoader, "/assets/char.glb");
  const mixer = useMemo(() => new THREE.AnimationMixer(), [])
  const group = useRef()
  useEffect(() => {
    mixer.clipAction(gltf.animations[0], group.current).play()
  gltf.materials['Material.001'].emissive = new THREE.Color(0xAA0000)

  }, [])

  useFrame((state, delta) => mixer.update(delta))

  return <group ref={group} {...props}>
    <primitive animate={[0, 1, 2]} object={gltf.nodes.Scene} dispose={null} />
  </group>
};

const Fox = (props) => {
  const gltf = useLoader(GLTFLoader, "/assets/fox.glb");
  const mixer = useMemo(() => new THREE.AnimationMixer(), [])
  const group = useRef()
  useEffect(() => {
    mixer.clipAction(gltf.animations[0], group.current).play()

  }, [])

  useFrame((state, delta) => mixer.update(delta))


  return <group ref={group} {...props}>
    <primitive animate={[0, 1, 2]} object={gltf.nodes.Scene} dispose={null} />
  </group>
};

function App() {
  return (
    <Canvas camera={{ fov: 45, position: [1.8, 1.2, 5] }}>
      <ambientLight intensity={2} />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <Floor />
        <Fox />
        <Laptop />
        <Char />
      </Suspense>
    </Canvas>
  )
}

export default App;
